import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import {
  Text,
  Button,
  ButtonGroup,
  Layout,
  LayoutItem,
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
} from '@audi/audi-ui-react';
import isMobile from 'ismobilejs';
import { UeElement } from '@oneaudi/falcon-tools';
import {
  DIMENSION_M,
  DIMENSION_S,
  DIMENSION_XL,
  DIMENSION_XS,
  toSrc,
  toSrcSetPartial,
} from './utils/url-utils';
import { ParallaxTeaserProps, WltpProps } from './ComponentTypes';

export * from './ComponentTypes';

const StyledImg = styled.img`
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  will-change: transform;
  backface-visibility: hidden;
`;
StyledImg.displayName = 'StyledImg';

const ParallaxTeaser = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;
ParallaxTeaser.displayName = 'ParallaxTeaser';

const ParallaxArea = styled.div`
  position: relative;
  overflow: hidden;
  height: 1000px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 1400px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    height: 1600px;
  }
`;
ParallaxArea.displayName = 'ParallaxArea';

const StyledLayout = styled(Layout)`
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  left: 0;
  z-index: 1;
  will-change: transform;
`;
StyledLayout.displayName = 'StyledLayout';

const StyledLayoutItemText = styled(LayoutItem)`
  box-sizing: border-box;
  background: var(${(props) => props.theme.colors.background.level['0']});
  padding: var(${(props) => props.theme.responsive.spacing.xxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    position: relative;
    left: 4%;
  }
`;

const LegalArea = styled(Layout)`
  background-color: var(${(props) => props.theme.colors.background.level['0']});
`;
LegalArea.displayName = 'LegalArea';

const WltpWrapper = styled.span`
  display: block;
`;

const WltpSpan = styled.span``;
WltpSpan.displayName = 'WltpSpan';

class Debouncer {
  private lastTimestamp = 0;

  public debounce(callback: () => void, wait: number) {
    const currentTimestamp = Date.now();

    if (currentTimestamp - this.lastTimestamp >= wait) {
      callback();
      this.lastTimestamp = currentTimestamp;
    }
  }
}

const WltpValuesComponent = ({
  formattedConsumption,
  formattedEmission,
  formattedCo2Class,
  formattedDischargedConsumption,
  formattedDischargedCo2Class,
}: WltpProps) => {
  return (
    <WltpWrapper>
      <WltpSpan data-testid="consumption">{formattedConsumption}</WltpSpan>
      <WltpSpan data-testid="emission">; {formattedEmission}</WltpSpan>
      {formattedCo2Class && <WltpSpan data-testid="co2Class">; {formattedCo2Class}</WltpSpan>}
      {formattedDischargedConsumption && (
        <WltpSpan data-testid="dischargedConsumption">; {formattedDischargedConsumption}</WltpSpan>
      )}
      {formattedDischargedCo2Class && (
        <WltpSpan data-testid="dischargedCo2Class">; {formattedDischargedCo2Class}</WltpSpan>
      )}
    </WltpWrapper>
  );
};

export const ParallaxTeaserComponent = (parallaxTeaserProps: ParallaxTeaserProps) => {
  const { headline, copy, links, images, linkTracking, legalData, linkType } = parallaxTeaserProps;
  const { imageXS, imageS, imageM, imageXL, altText = '' } = images;

  const [disableAnimation, setDisableAnimation] = useState(true);
  const parallaxRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const textboxRef = useRef<HTMLDivElement>(null);

  const imageScrollRate = 0.25;
  const textScrollRate = -0.125;
  const debouncer = new Debouncer();
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  // Fix innerHeight for mobile browsers that hide url- and navigation bar, to avoid flickering on scroll.
  // let windowInnerHeight = window.innerHeight;
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);
  let teaserDOMRect: DOMRect;

  function updateTeaserDOMRect() {
    if (!parallaxRef.current) {
      throw new Error(`The Teaser element could not be found!`);
    }
    teaserDOMRect = parallaxRef.current.getBoundingClientRect();
  }

  function getTeaserDOMRect() {
    if (!teaserDOMRect) {
      updateTeaserDOMRect();
    }
    return teaserDOMRect;
  }

  function isScrolledNearView(): boolean {
    const rect = getTeaserDOMRect();
    const topIsNearView = rect.top / 2 < window.innerHeight;
    const bottomIsNearView = rect.bottom > window.innerHeight / -2;
    return topIsNearView && bottomIsNearView;
  }

  function updateImageTop() {
    if (imageRef.current) {
      imageRef.current.style.top = `${(windowInnerHeight * imageScrollRate) / -2}px`;
    }
  }

  function updateTextboxTop() {
    if (parallaxRef.current && textboxRef.current) {
      const teaserRect = parallaxRef.current.getBoundingClientRect();
      const offsetToCenterTeaser = (windowInnerHeight - teaserRect.height) / 2;
      const isWindowSmallerThanTeaser = windowInnerHeight <= teaserRect.height;

      if (isWindowSmallerThanTeaser) {
        /**
         * If the window is smaller than the teaser, the textbox position will be at the
         * top of the window if the teaser is scrolled to its center.
         * The factor 2 is an additional visual ajustment.
         * */
        textboxRef.current.style.top = `${
          (-offsetToCenterTeaser - offsetToCenterTeaser * textScrollRate) / 2
        }px`;
      } else {
        textboxRef.current.style.top = `${-offsetToCenterTeaser * textScrollRate}px`;
      }
    }
  }

  function updateParallaxImageTransform(transformY: number) {
    // eslint-disable-next-line no-unused-expressions
    if (imageRef.current) {
      imageRef.current.style.transform = `translate3d(-50%, ${transformY}px, 0)`;
    }
  }

  function updateParallaxTextTransform(transformY: number) {
    if (textboxRef.current) {
      textboxRef.current.style.transform = `translate3d(0, ${transformY}px, 0)`;
    }
  }

  const updatePosition = useCallback(() => {
    const rect = getTeaserDOMRect();
    const offset = windowInnerHeight - rect.bottom;

    updateParallaxImageTransform(offset * imageScrollRate);
    updateParallaxTextTransform(offset * textScrollRate);
  }, [windowInnerHeight]);

  function resetPosition() {
    updateParallaxImageTransform(0);
    updateParallaxTextTransform(0);
  }

  function update() {
    updateTeaserDOMRect();
    if (isScrolledNearView()) {
      window.requestAnimationFrame(updatePosition);
    }
  }

  const onScroll = useCallback(() => {
    debouncer.debounce(update, 10);
  }, [windowInnerHeight]);

  let orientationChanged = false;
  const onWindowResize = useCallback(() => {
    if (!isMobileBrowser || orientationChanged) {
      setWindowInnerHeight(window.innerHeight);
      orientationChanged = false;
    }
    debouncer.debounce(() => {
      updateImageTop();
      updateTextboxTop();
    }, 10);
    updatePosition();
  }, [isMobileBrowser, windowInnerHeight]);

  const onOrientationChange = useCallback(() => {
    orientationChanged = true;
  }, []);

  function addEventListener() {
    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onWindowResize, { passive: true });
    window.addEventListener('orientationchange', onOrientationChange);
  }

  function removeEventListener() {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onWindowResize);
    window.removeEventListener('orientationchange', onOrientationChange);
  }

  function addMediaQueryEventListener(
    mediaQuery: MediaQueryList,
    // eslint-disable-next-line no-unused-vars
    listener: (e: MediaQueryListEvent) => void,
  ) {
    if (typeof mediaQuery.addEventListener === 'function') {
      mediaQuery.addEventListener('change', listener, { passive: true });
    } else {
      // Fallback for older Browser e.g. Safari < v5
      mediaQuery.addListener(listener);
    }
  }

  function removeMediaQueryEventListener(
    mediaQuery: MediaQueryList,
    // eslint-disable-next-line no-unused-vars
    listener: (e: MediaQueryListEvent) => void,
  ) {
    if (typeof mediaQuery.removeEventListener === 'function') {
      mediaQuery.removeEventListener('change', listener);
    } else {
      // Fallback for older Browser e.g. Safari < v5
      mediaQuery.removeListener(listener);
    }
  }

  function updateDisableAnimation(mediaQuery: MediaQueryList | MediaQueryListEvent) {
    setDisableAnimation(mediaQuery.matches);
  }

  function getLinkVariant(vLinkType: 'button' | 'link', i: number) {
    if (vLinkType === 'link') {
      return 'text';
    }
    if (i === 0) {
      return 'primary';
    }
    return 'secondary';
  }

  // reduce motion
  useEffect(() => {
    if (isMobile(window?.navigator).any) {
      setIsMobileBrowser(true);
    }
    setWindowInnerHeight(window.innerHeight);

    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    updateDisableAnimation(mediaQuery);

    addMediaQueryEventListener(mediaQuery, updateDisableAnimation);

    return () => {
      removeMediaQueryEventListener(mediaQuery, updateDisableAnimation);
    };
  }, []);

  // reduce motion
  useEffect(() => {
    updateImageTop();
  }, [windowInnerHeight]);

  useEffect(() => {
    updateTextboxTop();

    if (disableAnimation) {
      removeEventListener();
      resetPosition();
    } else {
      addEventListener();
      updatePosition();
    }

    return () => removeEventListener();
  }, [disableAnimation, windowInnerHeight]);

  return (
    <ParallaxTeaser data-testid="parallaxTeaserId">
      <ParallaxArea ref={parallaxRef}>
        <picture>
          <UeElement type="media" property="images_imageXL" label="Image XL">
            {(ueProps) => (
              <source
                media="(min-width:1440px)"
                srcSet={toSrcSetPartial(imageXL, DIMENSION_XL.width)}
                sizes="(min-width:1920px) 1920px, 100vw"
                {...DIMENSION_XL}
                {...ueProps}
              />
            )}
          </UeElement>
          <UeElement type="media" property="images_imageM" label="Image M">
            {(ueProps) => (
              <source
                media="(min-width:768px)"
                srcSet={toSrcSetPartial(imageM, DIMENSION_M.width)}
                sizes="100vw"
                {...DIMENSION_M}
                {...ueProps}
              />
            )}
          </UeElement>
          <UeElement type="media" property="images_imageS" label="Image S">
            {(ueProps) => (
              <source
                media="(min-width:375px)"
                srcSet={toSrcSetPartial(imageS, DIMENSION_S.width)}
                sizes="100vw"
                {...DIMENSION_S}
                {...ueProps}
              />
            )}
          </UeElement>
          <UeElement type="media" property="images_imageXS" label="Image XS">
            {(ueProps) => (
              <StyledImg
                ref={imageRef}
                data-testid="image"
                alt={altText}
                loading="lazy"
                src={toSrc(imageXS, DIMENSION_XS.width)}
                srcSet={toSrcSetPartial(imageXS, DIMENSION_XS.width)}
                sizes="100vw"
                {...DIMENSION_XS}
                {...ueProps}
              />
            )}
          </UeElement>
        </picture>
        <StyledLayout ref={textboxRef} justify={{ xs: 'center', m: 'start' }}>
          <StyledLayoutItemText
            spaceStackStart="xxxl"
            basis={{ xs: '86%', m: '56%', xl: '44%' }}
            shrink="0"
          >
            <Text as="h2" variant="order2" weight="bold" spaceStackEnd="l" data-testid="headline">
              <UeElement type="text" property="headline" label="Headline">
                {headline}
              </UeElement>
            </Text>
            <Text as="p" variant="copy1" spaceStackEnd="xl" data-testid="copy-text">
              <UeElement type="text" property="copy" label="Copy Text">
                {copy}
              </UeElement>
            </Text>
            {!!links?.length && (
              <ButtonGroup variant={linkType === 'button' ? 'block-buttons' : 'text-buttons'}>
                {links?.map(({ href, text }, i) => (
                  <Button
                    href={href}
                    variant={getLinkVariant(linkType, i)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${i}`}
                    onClick={() => linkTracking && linkTracking(href, text, '')}
                    data-testid={`cta-${i}`}
                  >
                    <UeElement type="text" property={`links_${i}_text`} label="Link">
                      {text}
                    </UeElement>
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </StyledLayoutItemText>
        </StyledLayout>
      </ParallaxArea>
      {(legalData.wltpData.length > 0 || legalData.additionalText) && (
        <ThemeProvider theme={legalData.theme === 'dark' ? audiDarkTheme : audiLightTheme}>
          <LegalArea justify="center" data-testid="legal-text-area">
            <LayoutItem
              basis={{ xs: '86%', m: '92%' }}
              shrink="0"
              spaceStackStart="l"
              spaceStackEnd="xxxl"
            >
              <Text variant="copy2">
                {legalData.wltpData &&
                  legalData.wltpData.map((data, index) => (
                    <WltpValuesComponent {...data} key={`${index.toString}`} />
                  ))}
                {legalData.additionalText && (
                  <WltpSpan data-testid="additional-legal-text">
                    <UeElement
                      type="text"
                      property="legalData_additionalText"
                      label="Additional Legal Text (optional)"
                    >
                      {legalData.additionalText}
                    </UeElement>
                  </WltpSpan>
                )}
              </Text>
            </LayoutItem>
          </LegalArea>
        </ThemeProvider>
      )}
    </ParallaxTeaser>
  );
};
