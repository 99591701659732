import React from 'react';
import { Context } from './index';

export interface TrackingManager {
  // eslint-disable-next-line no-unused-vars
  ready(version: string): void;
  // eslint-disable-next-line no-unused-vars
  click(headline: string, targetURL: string, text: string, clickID: string): void;
  // eslint-disable-next-line no-unused-vars
  impression(headline: string): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'parallax-teaser',
    currentURL: `${host}${pathname}`,
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    component: {
      componentInfo: {
        implementer: 2,
        version,
      },
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = React.useContext(Context);
  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) {
          logger.debug('Sending tracking event: ', payload);
        }
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) {
          logger.debug('Sending tracking event: ', payload);
        }

        const componentPayload = createComponentPayload(version);
        if (logger) {
          logger.debug('Sending component info', componentPayload);
        }

        // TODO update tracking service with componentPayload as second argument
        // when the service has been updated
        trackingService.track(payload);
      }
    }

    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },

      click(headline, targetURL, text, clickId) {
        const checkLinkTarget = (url: string) => {
          const tempLink = document.createElement('a');
          tempLink.href = url;

          if (tempLink.hostname === window.location.hostname) {
            return 'internal_link';
          }

          return 'exit_link';
        };

        track(checkLinkTarget(targetURL), 'parallax teaser - click', {
          label: text,
          elementName: 'text link',
          targetURL,
          clickID: clickId,
          value: headline,
          pos: '',
        });
      },

      impression(headline) {
        track('impression', 'parallax teaser - impression', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: headline,
          pos: '',
        });
      },
    };
  }, [trackingService]);
}
