/* eslint-disable react/display-name */
import React from 'react';
import { ReactFeatureApp } from '@feature-hub/react';
import { FeatureAppDefinition } from '@feature-hub/core';

import { FootnoteContextProvider, InViewContextProvider } from '@oneaudi/feature-app-utils';
import AsyncFeatureApp from './FeatureApp';
import { ContextProvider } from './context';
import { createInitialState } from './utils/stateUtils';
import { AsyncStateHolder, Dependencies } from './FeatureAppTypes';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

/**
 * It holds a function to retrieve the State needed by the feature app when it is rendered on the server
 * It holds the State object created from the serialized state on the client
 */

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    externals: {
      '@audi/audi-ui-react': '^3.4.0',
      '@feature-hub/react': '^3.5.0',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '^5.3.0',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({ featureServices }) => {
    const {
      'audi-tracking-service': trackingService,
      'audi-content-service': contentService,
      's2:logger': logger,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      'gfa:locale-service': localeService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
    } = featureServices;

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName = 'audi-parallax-teaser';
    }
    const initialContent = contentService.getContent();
    const content = mapHeadlessContent(initialContent);

    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            asyncStateHolder = await createInitialState(
              content,
              vueFormatterService,
              localeService,
              logger,
            );
            serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
          }
        })(),
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState);
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );
        if (content) {
          asyncStateHolder = () =>
            createInitialState(content, vueFormatterService, localeService, logger);
        }
      }
    }

    return {
      render: () => {
        return (
          <InViewContextProvider logger={logger}>
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                <ContextProvider featureServices={featureServices}>
                  <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                </ContextProvider>
              </div>
            </FootnoteContextProvider>
          </InViewContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
