import { Asset } from '@oneaudi/falcon-tools';
import { Content, HeadlessContent, Link } from '../EditorContentTypes';

export const mapLinks = (content: HeadlessContent) => {
  const links: Link[] = [];
  if (content.fields.links_0_href && content.fields.links_0_text) {
    links.push({
      href: content.fields.links_0_href ?? 'google.de',
      text: content.fields.links_0_text ?? 'PLACEHOLDER TEXT',
    });
  }
  if (content.fields.links_1_href && content.fields.links_1_text) {
    links.push({
      href: content.fields.links_1_href,
      text: content.fields.links_1_text,
    });
  }
  return links;
};

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalizedContent: Content = {
      headline: content.fields.headline ?? 'Lorem ipsum dolor sit amet',
      copy:
        content.fields.copy ??
        'Lorem ipsum dolor sit amet consectetur adipiscing elit eget vehicula, magnis laoreet elementum eleifend diam feugiat maecenas nisi inceptos eu, euismod cursus sodales sociosqu eros convallis nisl interdum.',
      links: mapLinks(content),
      linkType: content.fields.linkType ?? 'button',
      images: {
        imageXS: {
          assetValue: content.fields.images_imageXS
            ? (content.fields.images_imageXS as Asset).path
            : 'https://dummyimage.com/787x1250',
        },
        imageS: {
          assetValue: content.fields.images_imageS
            ? (content.fields.images_imageS as Asset).path
            : 'https://dummyimage.com/786x1280',
        },
        imageM: {
          assetValue: content.fields.images_imageM
            ? (content.fields.images_imageM as Asset).path
            : 'https://dummyimage.com/1053x1280',
        },
        imageXL: {
          assetValue: content.fields.images_imageXL
            ? (content.fields.images_imageXL as Asset).path
            : 'https://dummyimage.com/1920x1280',
        },
        altText: content.fields.images_altText,
      },
      legalData: {
        theme: content.fields.legalData_theme ?? 'light',
        additionalText: content.fields.legalData_additionalText,
        wltpKeys: [],
      },
    };
    if (content.fields.legalData_wltpKeys.length > 0) {
      content.fields.legalData_wltpKeys.forEach((wltpKey) => {
        normalizedContent.legalData.wltpKeys.push(wltpKey);
      });
    }

    return normalizedContent;
  }
  return content as Content;
};
